export const scrollToElement = (elementId: string) => {
    const element = document.getElementById(elementId)
    if (element) {
        let headerOffset = 100;
        let elementPosition = element.getBoundingClientRect().top;
        let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }
}
