import './Supervision.scss'
import {useRef} from "react";
import {useTranslation} from "react-i18next";
import useOnScreen from "../../hooks/useOnScreen";

export const Supervision = () => {
    const {t} = useTranslation()
    const elementRef = useRef<HTMLDivElement>(null)
    const element = useOnScreen(elementRef)
    return <section className={'supervision-sec'} id='control'>
        <div className="G-container">
            <h2>{t('advantages-title')}</h2>
            <div ref={elementRef}
                 className={`supervision-cnt G-flex-wrap G-animation ${element ? 'G-show-element' : ''}`}>
                <div className={'supervision-box'}>
                    <h4>{t('advantages-title-1')}</h4>
                    <span></span>
                    <p>{t('advantages-description-1')}</p>
                </div>
                <div className={'supervision-box'}>
                    <h4>{t('advantages-title-2')}</h4>
                    <span></span>
                    <p>{t('advantages-description-2')}</p>
                </div>
                <div className={'supervision-box'}>
                    <h4>{t('advantages-title-3')}</h4>
                    <span></span>
                    <p>{t('advantages-description-3')}</p>
                </div>
                <div className={'supervision-box'}>
                    <h4>{t('advantages-title-4')}</h4>
                    <span></span>
                    <p>{t('advantages-description-4')}</p>
                </div>
                <div className={'supervision-box'}>
                    <h4>{t('advantages-title-5')}</h4>
                    <span></span>
                    <p>{t('advantages-description-5')}</p>
                </div>
            </div>
        </div>
    </section>
}
