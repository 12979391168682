import {Header} from "./components/Header/Header";
import {Map} from "./components/Map/Map";
import {Footer} from "./components/Footer/Footer";
import {Banner} from "./components/Banner/Banner";
import {Project} from "./components/Projects/Project";
import {About} from "./components/About/About";
import {Supervision} from "./components/Supervision/Supervision";
import {Services} from "./components/Services/Services";

export const App = () => {
    return <>
        <Header/>
        <Banner/>
        <Project/>
        <Supervision/>
        <Services/>
        <About/>
        <Map/>
        <Footer/>
    </>;
}
