import './Projects.scss'
import {useRef} from "react";
import {useTranslation} from "react-i18next";
import projectOne from '../../assets/images/unnamed.jpg'
import {IconArrowLeft} from "../../assets/icons/IconArrowLeft";
import {IconArrowRight} from "../../assets/icons/IconArrowRight";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper/modules";
import 'swiper/css';
import useOnScreen from "../../hooks/useOnScreen";


interface IProjectCard {
    name: string,
    images: string[]
}

export const Project = () => {
    const {t} = useTranslation()
    const elementRef = useRef<HTMLDivElement>(null)
    const element = useOnScreen(elementRef)
    const projects: IProjectCard[] = [
        {
            name: 'project-name-1',
            images: [
                projectOne,
                projectOne,
                projectOne]
        },
        {
            name: 'project-name-1',
            images: [
                projectOne,
                projectOne,
                projectOne]
        },
        {
            name: 'project-name-1',
            images: [
                projectOne,
                projectOne,
                projectOne]
        }, {
            name: 'project-name-1',
            images: [
                projectOne,
                projectOne,
                projectOne]
        }, {
            name: 'project-name-1',
            images: [
                projectOne,
                projectOne,
                projectOne]
        }, {
            name: 'project-name-1',
            images: [
                projectOne,
                projectOne,
                projectOne]
        }
    ]
    return <section className={'project-sec'} id='projects' >
        <div className="G-container">
            <h2>{t('project-title')}</h2>
            <div  ref={elementRef} className={`project-wrap G-flex-wrap G-animation ${element ? 'G-show-element' : ''}`}>
                {projects.map((project, i) => <ProjectItem key={i} {...project}/>)}
            </div>
        </div>
    </section>
}


const ProjectItem = ({name, images}: IProjectCard) => {
    const {t} = useTranslation()
    const settings = {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        speed: 500,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        modules: [Navigation]
    }

    return <div className={'project-box'}>
        <div className={'project-img-slider'}>
            <Swiper {...settings}>
                {images.map((img, i) => <SwiperSlide key={i}><img className={'project-img'} src={img}
                                                                  alt=""/></SwiperSlide>)}
                <button className={'swiper-arrows-item G-center swiper-button-prev'}><IconArrowLeft size={20}/></button>
                <button className={'swiper-arrows-item G-center swiper-button-next'}><IconArrowRight size={20}/>
                </button>
            </Swiper>
        </div>
        <div className={'project-info'}>
            <h3>{t(name)}</h3>
        </div>
    </div>
}
