import './Services.scss'
import { useRef} from "react";
import {useTranslation} from "react-i18next";
import servicesPhoto from '../../assets/images/unnamed.jpg';
import useOnScreen from "../../hooks/useOnScreen";

export const Services = () => {
    const {t} = useTranslation()
    const elementRef_1 = useRef<HTMLDivElement>(null)
    const elementRef_2 = useRef<HTMLDivElement>(null)
    const element_1 = useOnScreen(elementRef_1)
    const element_2 = useOnScreen(elementRef_2)
    return <section className={'services-sec'} id='services'>
        <div className="G-container">
            <h2>{t('services-title')}</h2>
            <div ref={elementRef_1} className={`services-cnt G-animation ${element_1 ? 'G-show-element' : ''}`}>
                <h4>{t('services-name-1')}</h4>
                <p>{t('services-text-1')}</p>
                <div className={'services-photo G-align-center'}>
                    <img src={(servicesPhoto)} alt="services"/>
                    <img src={(servicesPhoto)} alt="services"/>
                </div>
            </div>
            <div ref={elementRef_2} className={`services-cnt G-animation ${element_2 ? 'G-show-element' : ''}`}>
                <h4>{t('services-name-2')}</h4>
                <p>{t('services-text-2')}</p>
                <div className={'services-photo G-align-center'}>
                    <img src={(servicesPhoto)} alt="services"/>
                    <img src={(servicesPhoto)} alt="services"/>
                </div>
            </div>
        </div>
    </section>
}
