import './Banner.scss'
import {useRef} from "react";
import {useTranslation} from "react-i18next";
import bannerPhoto from '../../assets/images/monitoring.jpg'
import {scrollToElement} from "../../utils/utils";
import useOnScreen from "../../hooks/useOnScreen";

export const Banner = () => {
    const {t} = useTranslation()
    const elementRef = useRef<HTMLDivElement>(null)
    const element = useOnScreen(elementRef)
    return <section className={'banner-sec'} style={{backgroundImage: `url('${bannerPhoto}')`}}>
        <div className="G-container">
            <div  ref={elementRef} className={`banner-title G-animation ${element ? 'G-show-element' : ''}`}>
                <h1>{t('banner-title')}</h1>
                <p>{t('banner-text')}</p>
                <div className={'see-more'} onClick={() => {
                    scrollToElement('projects')
                }}>{t('href-down')}</div>
            </div>
        </div>
    </section>
}
