import {RefObject, useEffect, useMemo, useState} from "react";

export default function useOnScreen(ref: RefObject<HTMLElement>, margin: number = 100) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = useMemo(() => new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting), {rootMargin: `0px 0px -${margin}px 0px`}
        // eslint-disable-next-line
    ), [ref])


    useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current)
            return () => observer.disconnect()
        }
        // eslint-disable-next-line
    }, [])

    return isIntersecting
}
