import './About.scss'
import {useRef} from "react";
import {useTranslation} from "react-i18next";
import aboutPhoto from '../../assets/images/monitoring.jpg'
import useOnScreen from "../../hooks/useOnScreen";

export const About = () => {
    const {t} = useTranslation()
    const elementRef = useRef<HTMLDivElement>(null)
    const element = useOnScreen(elementRef)
    return <section id='about' ref={elementRef} className={`about-sec G-animation ${element ? 'G-show-element' : ''}`}>
        <div className="G-container">
            <div className={'about-cnt'}>
                <div className={'about-info'}>
                    <h2>{t('about-title')}</h2>
                    <p>{t('about-text')}</p>
                </div>
                <div className={'about-photo'}>
                    <img src={(aboutPhoto)} alt="about-us"/>
                </div>
            </div>
        </div>
    </section>
}
